/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import * as CurrencySymbols from "../../models/currency-symbols";
import { RecurringTypes } from "../../models/recurring-types";

const premiumAmounts = {
  GBP: 14.99,
  USD: 17.99,
  EUR: 16.99,
  promo: 9.99
};

const promoPeriod = {
  from: new Date("December 8, 2023 23:59:59").getTime(),
  to: new Date("January 31, 2024 23:59:59").getTime()
}

export class DonationDetails extends Component {
  today = new Date().getTime();
  isCharityPremiumSubscription = false;

  constructor(props) {
    super(props);

    const {
      charityDetails,
      onboardProviders,
      amount,
      source,
      currencyFromUrl,
      isCharityPremiumSubscription
    } = this.props;
    const charity = charityDetails.charity;
    const isContribution = charityDetails.contribution;
    const isUkCharity = charity.country.code === "GBR" ? true : false;
    const isCorporateContribution = source === "CONTRIBUTIONS_LIST";
    this.isCharityPremiumSubscription = isCharityPremiumSubscription;

    let currency = "GBP";
    let selectedAmount = charityDetails.predefinedDonation
      ? DonationDetails.addedExtraZero(
        charityDetails.predefinedDonation.defaultAmount
      )
      : 20;

    if (amount) {
      selectedAmount = DonationDetails.addedExtraZero(
        Math.round(amount * 100) / 100
      );
    }

    let firstAmountOption =
      charityDetails.predefinedDonation &&
        charityDetails.predefinedDonation.firstAmount >= 0
        ? DonationDetails.addedExtraZero(
          charityDetails.predefinedDonation.firstAmount
        )
        : 10;
    const secondAmountOption =
      charityDetails.predefinedDonation &&
        charityDetails.predefinedDonation.secondAmount >= 0
        ? DonationDetails.addedExtraZero(
          charityDetails.predefinedDonation.secondAmount
        )
        : 20;
    const thirdAmountOption =
      charityDetails.predefinedDonation &&
        charityDetails.predefinedDonation.thirdAmount >= 0
        ? DonationDetails.addedExtraZero(
          charityDetails.predefinedDonation.thirdAmount
        )
        : 50;
    const fourthAmountOption =
      charityDetails.predefinedDonation &&
        charityDetails.predefinedDonation.fourthAmount >= 0
        ? DonationDetails.addedExtraZero(
          charityDetails.predefinedDonation.fourthAmount
        )
        : 100;
    const supportedCurrencies = onboardProviders.currencies;

    if (
      typeof supportedCurrencies !== "undefined" &&
      supportedCurrencies !== null
    ) {
      const selectedCurrency = supportedCurrencies
        ? supportedCurrencies[0]
        : null;

      if (currencyFromUrl) {
        const result = supportedCurrencies.filter(
          (method) => method.currency === currencyFromUrl
        );

        if (result.length === 0) {
          if (selectedCurrency !== null) {
            currency = selectedCurrency.currency;
          }
        } else {
          currency = currencyFromUrl;
        }
      } else {
        const result = supportedCurrencies.filter(
          (method) => method.currency === "GBP"
        );

        if (result.length === 0) {
          if (selectedCurrency !== null) {
            currency = selectedCurrency.currency;
          }
        }
      }
    }

    const recurringType = this.setPredefinedRecurringType(charityDetails);
    const bankFeesAmount = this.calculateBankFees(selectedAmount, currency);
    const premiumAmount = this.getPremiumAmount(currency);

    if (premiumAmount) {
      selectedAmount = premiumAmount;
      firstAmountOption = premiumAmount;
    }

    this.state = {
      currency: currency,
      currencySymbol: CurrencySymbols.CurrencySymbols[currency],
      amount: selectedAmount,
      selectedAmount: selectedAmount,
      recurringType: recurringType !== null ? recurringType : RecurringTypes.ONE_OFF,
      hasMsg: false,
      message: "",
      isAnonymous: false,
      giftAid: false,
      bankFeesAmount,
      bankFeesAmountChecked: false,
      isUkCharity: isUkCharity,
      isContribution: isContribution,
      isCorporateContribution: isCorporateContribution,
      firstAmountOption: firstAmountOption,
      secondAmountOption: secondAmountOption,
      thirdAmountOption: thirdAmountOption,
      fourthAmountOption: fourthAmountOption,
    };
  }

  refreshPage() {
    window.location.reload(false);
  }

  static getDerivedStateFromProps(props) {
    if (props.charityDetails && props.charityDetails.ecommerceProject) {
      return { hasMsg: true };
    }

    return null;
  }

  getPremiumAmount(currency) {
    if (this.isCharityPremiumSubscription) {
      if (this.today >= promoPeriod.from && this.today <= promoPeriod.to) {
        return premiumAmounts.promo
      } else {
        return premiumAmounts[currency] || 0;
      }
    }
    return null;
  }

  changeCurrency(event) {
    const currency = event.target.value;
    if (this.state.currency === currency) { return; }
    const premiumAmount = this.isCharityPremiumSubscription && this.getPremiumAmount(currency);
    const amountWithoutPercentage = this.state.bankFeesAmountChecked ? Number(this.state.amount) - this.state.bankFeesAmount : Number(this.state.amount);
    const bankFeesAmount = this.calculateBankFees(amountWithoutPercentage, currency);

    this.setState({
      firstAmountOption: premiumAmount || this.state.firstAmountOption,
      selectedAmount: premiumAmount || this.state.selectedAmount,
      currencySymbol: CurrencySymbols.CurrencySymbols[event.target.value],
      currency,
      bankFeesAmount,
      amount: this.state.bankFeesAmountChecked ? amountWithoutPercentage + bankFeesAmount : premiumAmount || this.state.amount
    });
  }

  setAmount(value) {
    if (this.isCharityPremiumSubscription) { return; }
    const amount = value !== "" ? Number(value) : ""
    const bankFeesAmount = amount === 0 ? 0 : this.calculateBankFees(amount);
    this.setState({ amount, bankFeesAmount, bankFeesAmountChecked: false, selectedAmount: 0 });
  }

  setAmountWithExtraZero(value) {
    if (this.isCharityPremiumSubscription) { return; }
    const amount = value !== "" ? DonationDetails.addedExtraZero(value) : ""
    this.setState({ amount });
  }

  setActive(value) {
    if (this.isCharityPremiumSubscription) { return; }
    const selectedAmount = value !== '' ? Number(value) : '';
    const bankFeesAmount = this.calculateBankFees(selectedAmount);
    this.setState({
      selectedAmount,
      bankFeesAmount,
      bankFeesAmountChecked: false,
    });
  }

  setRecurringType(value) {
    if (this.isCharityPremiumSubscription) { return; }
    this.setState({ recurringType: value });
  }

  addMessage(value) {
    this.setState({ hasMsg: value });
  }

  changeMessage(value) {
    this.setState({ message: value });
  }

  changeAnonymousDonation(value) {
    this.setState({ isAnonymous: value }) ;
  }

  changeGiftAid(value) {
    this.setState({ giftAid: value });
  }

  setCoverBankFees(isChecked) {
    if (!this.state.amount) { return; }
    const amount = DonationDetails.addedExtraZero(isChecked ? Number(this.state.amount) + this.state.bankFeesAmount : Number(this.state.amount) - this.state.bankFeesAmount);
    this.setState({ bankFeesAmountChecked: isChecked, amount });
  }

  calculateBankFees(amount, currency) {
    const percentage = (currency || this.state.currency) === "USD" ? 3 : 2;
    return (Number(amount || this.state.amount) / 100) * percentage
  }

  setPredefinedRecurringType(charityDetails) {
    if (this.isCharityPremiumSubscription) {
      return RecurringTypes.MONTHLY;
    }

    if (charityDetails.predefinedDonation) {
      if (charityDetails.predefinedDonation.recurrenceType === "YEAR") {
        return RecurringTypes.YEARLY;
      }
      if (charityDetails.predefinedDonation.recurrenceInterval === 0) {
        return RecurringTypes.ONE_OFF;
      } else if (charityDetails.predefinedDonation.recurrenceInterval === 1) {
        return RecurringTypes.MONTHLY;
      } else {
        return RecurringTypes.QUARTERLY;
      }
    }
    return null;
  }

  static addedExtraZero(number) {
    return Number.parseFloat(number).toFixed(2);
  }

  sendDataToParentComponent() {
    this.setState({ showErrors: true });
    if (
      this.state.amount >= 0 &&
      !this.props.globalError &&
      !this.props.errorMsg
    ) {
      this.props.sendDataToParentComponent(this.state);
    }
  }

  render() {
    const {
      charityDetails,
      onboardProviders,
      selectedShoppingItemName,
      errorMsg,
      globalError,
      isCharityPremiumSubscription
    } = this.props;

    const charity = charityDetails.charity;
    const ecommerceProject = charityDetails.ecommerceProject;
    const description = !selectedShoppingItemName
      ? ecommerceProject
        ? "Please describe to your charity what goods you are paying for"
        : "Your donation message"
      : selectedShoppingItemName;

    return (
      <div id="donation-details">
        <div id="header">
          <img src={charity.logoUrl} alt="charity-logo" />
          <div id="charity-title">
            {ecommerceProject ? "Pay to" : "Donate to"}{" "}
            <span className="bold">{charity.name}</span>{" "}
            {ecommerceProject ? "for" : "in aid of"}{" "}
            <span className="bold">{charityDetails.title}</span>
          </div>
          <div className="container-charity-details">
            <p className="charity-details">
              Reg. No {charity.registrationNumber}, {charity.email}
            </p>
            <p className="charity-details">
              <span>{charity.address}, </span>
              <span>{charity.postCode}, </span>
              <span>{charity.city}, </span>
              {charity.country.name}
            </p>
          </div>
        </div>

        <div
          className={
            "global-error-container " +
            (globalError || errorMsg ? "" : "hidden")
          }
        >
          <div className="error-header">
            {globalError
              ? "Online Donations are currently not available - please check back in a while"
              : "Validation Error"}
          </div>
          <div className="error-message">
            {errorMsg && errorMsg}
            {globalError && (
              <button
                className="retry-button"
                type="button"
                onClick={() => this.refreshPage()}
              >
                Retry
              </button>
            )}
          </div>
        </div>
        {!globalError && (
          <div className="form-content">
            <div id="amount-bubble">
              <div id="amount-container">
                <div id="amount-title">
                  {ecommerceProject ? null : "Donation "}Amount
                </div>
                <div
                  className={
                    "amounts non-margin " +
                    (this.state.selectedAmount === this.state.firstAmountOption || isCharityPremiumSubscription
                      ? "active-amount"
                      : "")
                  }
                  onClick={(e) => {
                    this.setAmount(this.state.firstAmountOption);
                    this.setActive(this.state.firstAmountOption);
                  }}
                >
                  <div>
                    {this.state.currencySymbol}
                    {this.state.firstAmountOption}
                  </div>
                </div>

                <div
                  className={
                    "amounts " +
                    (this.state.selectedAmount === this.state.secondAmountOption
                      ? "active-amount"
                      : "")
                  }
                  onClick={(e) => {
                    this.setAmount(this.state.secondAmountOption);
                    this.setActive(this.state.secondAmountOption);
                  }}
                >
                  <div>
                    {this.state.currencySymbol}
                    {this.state.secondAmountOption}
                  </div>
                </div>
                <div
                  className={
                    "amounts " +
                    (this.state.selectedAmount === this.state.thirdAmountOption
                      ? "active-amount"
                      : "")
                  }
                  onClick={(e) => {
                    this.setAmount(this.state.thirdAmountOption);
                    this.setActive(this.state.thirdAmountOption);
                  }}
                >
                  <div>
                    {this.state.currencySymbol}
                    {this.state.thirdAmountOption}
                  </div>
                </div>
                <div
                  className={
                    "amounts " +
                    (this.state.selectedAmount === this.state.fourthAmountOption
                      ? "active-amount"
                      : "")
                  }
                  onClick={(e) => {
                    this.setAmount(this.state.fourthAmountOption);
                    this.setActive(this.state.fourthAmountOption);
                  }}
                >
                  <div>
                    {this.state.currencySymbol}
                    {this.state.fourthAmountOption}
                  </div>
                </div>

                <div id="amount-currency-container">
                  <div id="currency-symbol" className="float-left">
                    {this.state.currencySymbol}
                  </div>
                  <div className="float-left">
                    <input
                      className="donation-form-textbox-small other-amount"
                      id="other-amount"
                      type="number"
                      value={this.state.amount}
                      onChange={(e) => this.setAmount(e.target.value)}
                      onMouseLeave={(e) => this.setAmountWithExtraZero(Number(e.target.value))}
                    />
                  </div>
                  <div className="float-left">
                    <select
                      id="amount-currency"
                      className="donation-form-dropdown donation-currency"
                      onChange={this.changeCurrency.bind(this)}
                      defaultValue={this.state.currency}
                    >
                      {Array.isArray(onboardProviders.currencies) ? (
                        onboardProviders.currencies.map((el) => (
                          <option value={el.currency} key={el.currency}>
                            {el.currency}
                          </option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                </div>
                <div
                  className={
                    "validation-error " +
                    (this.state.amount !== "" ? "hidden" : "")
                  }
                >
                  The amount is required.
                </div>
                <div
                  className={
                    "validation-error " +
                    (this.state.amount >= 0 ? "hidden" : "")
                  }
                >
                  The amount must be a positive number.
                </div>

                <div id="recurring-options-container">
                  <div
                    id="donation_type_one_off"
                    className={
                      "recurring-option non-border " +
                      (this.state.recurringType === RecurringTypes.ONE_OFF
                        ? "active-recurring-type"
                        : "")
                    }
                    onClick={(e) => {
                      this.setRecurringType(RecurringTypes.ONE_OFF);
                    }}
                  >
                    <img
                      src={require("./../../images/check.png")}
                      alt="check"
                    />
                    One-off
                  </div>
                  <div
                    id="donation_type_monthly"
                    className={
                      "recurring-option " +
                      (this.state.recurringType === RecurringTypes.MONTHLY
                        ? "active-recurring-type"
                        : "")
                    }
                    onClick={(e) => {
                      this.setRecurringType(RecurringTypes.MONTHLY);
                    }}
                  >
                    <img
                      src={require("./../../images/check.png")}
                      alt="check"
                    />
                    Monthly
                  </div>
                  <div
                    id="donation_type_quaterly"
                    className={
                      "recurring-option " +
                      (this.state.recurringType === RecurringTypes.QUARTERLY
                        ? "active-recurring-type"
                        : "")
                    }
                    onClick={(e) => {
                      this.setRecurringType(RecurringTypes.QUARTERLY);
                    }}
                  >
                    <img
                      src={require("./../../images/check.png")}
                      alt="check"
                    />
                    Quarterly
                  </div>
                  <div
                    id="donation_type_yearly"
                    className={
                      "recurring-option " +
                      (this.state.recurringType === RecurringTypes.YEARLY
                        ? "active-recurring-type"
                        : "")
                    }
                    onClick={(e) => {
                      this.setRecurringType(RecurringTypes.YEARLY);
                    }}
                  >
                    <img
                      src={require("./../../images/check.png")}
                      alt="check"
                    />
                    Yearly
                  </div>
                </div>
              </div>
            </div>
            <div id="other-options">
              {charityDetails.coverFees && <div>
                <input
                  className="checkbox"
                  id="cover-bank-fees"
                  disabled={!this.state.amount}
                  onChange={(e) => {
                    this.setCoverBankFees(e.target.checked);
                  }}
                  checked={this.state.bankFeesAmountChecked}
                  type="checkbox"
                />
                <label
                  className="checkbox-label"
                  htmlFor="cover-bank-fees"
                  id="cover-bank-fees-label"
                >
                  <p>
                    I will tip to cover the {this.state.bankFeesAmount.toFixed(2)} {this.state.currency} bank processing fee.
                  </p>
                </label>
              </div>}

              <div
                id="gift-aid-container"
                className={
                  ecommerceProject ||
                    this.state.isContribution ||
                    this.state.isCorporateContribution ||
                    !this.state.isUkCharity ||
                    this.state.currency !== "GBP"
                    ? "hidden"
                    : ""
                }
              >
                <div id="gift-aid-header">
                  <div>
                    {this.state.amount
                      ? " Confirm the below in order to turn £" +
                      DonationDetails.addedExtraZero(this.state.amount) +
                      " into £" +
                      Math.round(this.state.amount * 1.25 * 100) / 100
                      : ""}
                  </div>
                  <div id="gift-aid-logo-container">
                    <img
                      id="gift-aid-logo"
                      src={require("./../../images/giftaidit-logo.png")}
                      alt="giftaidit-logo"
                    />
                  </div>
                </div>
                <div id="gift-aid-text">
                  I'm donating my own money, not anyone else's (including family
                  members) and not on behalf of a company or collection. My
                  donations aren't from the proceeds of a sale (e.g. bake sale,
                  auction, sweepstake). I'm not receiving anything for making
                  this donation (e.g. a ticket to an event or a raffle). I'm a
                  UK taxpayer and I understand that if the Gift Aid claimed on
                  my donations is more than the tax I pay in the current tax
                  year (on my income and/or capital gains), it's my
                  responsibility to pay the difference.
                </div>
                <input
                  className="checkbox"
                  id="gift-aid-input"
                  onClick={(e) => {
                    this.changeGiftAid(e.target.checked);
                  }}
                  type="checkbox"
                />
                <label
                  className="checkbox-label"
                  htmlFor="gift-aid-input"
                  id="gift-aid-input-label"
                >
                  <p>
                    I confirm the above and want to Gift Aid this donation and
                    any donations I make in the future or have made in the past
                    4 years, until I notify the charity otherwise
                  </p>
                </label>
              </div>

              <div
                className={
                  "delimiter " +
                  (ecommerceProject ||
                    this.state.isContribution ||
                    this.state.isCorporateContribution ||
                    !this.state.isUkCharity ||
                    this.state.currency !== "GBP"
                    ? "hidden"
                    : "")
                }
              ></div>
              <div>
                <input
                  className="checkbox"
                  id="anonymous-donation"
                  onClick={(e) => {
                    this.changeAnonymousDonation(e.target.checked);
                  }}
                  type="checkbox"
                />
                <label
                  className="checkbox-label"
                  htmlFor="anonymous-donation"
                  id="anonymous-donation-label"
                >
                  <p>
                    I wish my {ecommerceProject ? "purchase" : "donation"} to
                    stay anonymous to the public
                  </p>
                </label>
              </div>
              <div>
                <input
                  className="checkbox"
                  id="add-message"
                  type="checkbox"
                  defaultChecked={ecommerceProject}
                  onClick={(e) => {
                    this.addMessage(e.target.checked);
                  }}
                />
                <label
                  className="checkbox-label"
                  htmlFor="add-message"
                  id="add-message-label"
                >
                  <p>
                    {ecommerceProject
                      ? "Describe your purchase"
                      : "Add a message"}
                  </p>
                </label>
                <div className="non-margin">
                  <textarea
                    className={
                      "donation-form-textarea " +
                      (!this.state.hasMsg ? "hidden" : "")
                    }
                    id="message"
                    placeholder={description}
                    type="text"
                    value={this.state.message}
                    onChange={(e) => {
                      this.changeMessage(e.target.value);
                    }}
                  />
                </div>
                <div
                  className={
                    "validation-error " +
                    (this.state.showErrors ? "" : "hidden")
                  }
                >
                  The message cannot be empty.
                </div>
              </div>
            </div>

            <div
              id="continue-button"
              disabled={!!globalError}
              onClick={() => this.sendDataToParentComponent()}
            >
              Continue{" "}
              <img
                src={require("./../../images/arrow-right.png")}
                alt="arrow-right"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}