import * as partials from "../partials/types";

export const CONFIG_FILE = partials.createRequestTypes("CONFIG_FILE");
export const CHARITY_CORE_PUBLIC_TOKEN = partials.createRequestTypes(
  "CHARITY_CORE_PUBLIC_TOKEN"
);
export const DONATION_PROCESSING_PUBLIC_TOKEN = partials.createRequestTypes(
  "DONATION_PROCESSING_PUBLIC_TOKEN"
);
export const LOAD_CONFIG_FILE = "LOAD_CONFIG_FILE";
export const LOAD_PUBLIC_TOKEN = "LOAD_PUBLIC_TOKEN";

export * from "../partials/types";
