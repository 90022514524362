export const ValidationRegex = {
  emailRegex:
    /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]{1,20})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
  nameRegex: /^[A-Za-z\u0080-\u00FF]+[A-Za-z\-\s'.\s&\u0080-\u00FF]*$/,
  nonAlphaSymbols: /^[A-Za-z\u0080-\u00FF]+[A-Za-z\-\s'.\s&\u0080-\u00FF]*$/,
  phoneNumberPattern: /^\+?[\s\d]{3,20}$/,
  nonOnlyNumeric: /[^0-9]+/,
};

export function isValidEmail(value) {
  if (ValidationRegex.emailRegex.test(value)) {
    return true;
  }

  return false;
}

export function isValidName(value) {
  if (ValidationRegex.nameRegex.test(value)) {
    return true;
  }

  return false;
}

export function isValidAlpha(value) {
  if (ValidationRegex.nonAlphaSymbols.test(value)) {
    return true;
  }

  return false;
}

export function isValidPhoneNumber(value) {
  if (ValidationRegex.phoneNumberPattern.test(value)) {
    return true;
  }

  return false;
}

export function isValidCardNumber(value) {
  const luhnArr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
  let counter = 0;
  let incNum;
  let odd = false;
  const temp = String(value).replace(/[^\d]/g, "");
  if (temp.length === 0) return false;
  for (let i = temp.length - 1; i >= 0; --i) {
    incNum = parseInt(temp.charAt(i), 10);
    odd = !odd;
    counter += odd ? incNum : luhnArr[incNum];
  }
  if (counter % 10 !== 0) {
    return false;
  }
  return true;
}

export function isNumeric(value) {
  let trimValue;
  if (value !== null) {
    trimValue = value.replace(/ /g, "");
  }
  if (
    trimValue !== "" &&
    trimValue !== null &&
    ValidationRegex.nonOnlyNumeric.test(trimValue)
  ) {
    return false;
  }
  return true;
}
