import React, { Component } from "react";
import DonationForm from "./components/donation-form/DonationForm";
import * as actions from "./actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HttpRequestLoader from "./components/common/HttpRequestLoader";
import { ErrorComponent } from "./components/common/Error";
import {loadStripe} from '@stripe/stripe-js';
import queryString from "query-string";


class App extends Component {
  constructor(props) {
    super(props);
    const queryString = require("query-string");

    const queryParams = queryString.parse(this.props.location.search);
    this.state = {
      queryParams: queryParams,
      stripePromise: null,
      reCaptchaKey: null,
      reCaptchaVersion: null,
      configError: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.actions.loadConfigFile();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { DONATION_PROCESSING, RECAPTCHA_VERSION, RECAPTCHA_KEY_V2, RECAPTCHA_KEY_V3 } = nextProps.config;
    if (DONATION_PROCESSING.STRIPE_PUBLIC_KEY !== "") {
      const stripe = loadStripe(DONATION_PROCESSING.STRIPE_PUBLIC_KEY, {apiVersion: '2023-10-16'});
      this.setState({stripePromise: stripe});
    }
    const reCaptchaKey = RECAPTCHA_VERSION === "V2" ? RECAPTCHA_KEY_V2 : RECAPTCHA_KEY_V3;
    if (typeof RECAPTCHA_VERSION !== 'string' || typeof RECAPTCHA_KEY_V2 !== 'string' || typeof RECAPTCHA_KEY_V3 !== "string") {
      this.setState({ configError: true });
    }
    this.setState({ reCaptchaKey, reCaptchaVersion: RECAPTCHA_VERSION });


    // handle redirect payment result
    const params = queryString.parse(this.props.location.search);
    const redirectStatus = params['redirect_status'];
    const donorEmail = params['donorEmail'];
    const charityName = params['charityName'];
    const ecommerceProject = params['ecommerceProject'];
    const id = params['payment_intent'];
    const projectId = params['projectId'];
    const publicId = params['publicId'];
    const redirectParamsString = `&donorEmail=${donorEmail}&charityName=${charityName}&ecommerceProject=${ecommerceProject}&id=${id}&projectId=${projectId}&publicId=${publicId}`; 

    if (redirectStatus === 'succeeded') {
      window.location.href = '/status?transaction.status=success' + redirectParamsString;

    } else if (redirectStatus === 'failed') {
      window.location.href = '/status?transaction.status=failed' + redirectParamsString;
    }
  }

  render() {
    return (
      <>
        {!!this.state.reCaptchaKey && !this.state.configError && (
            <div>
              <HttpRequestLoader />
              <DonationForm
                charityPublicId={this.props.match.params.charityPublicId}
                projectId={this.props.match.params.projectId}
                amount={this.state.queryParams.amount}
                selectedShoppingItemName={
                  this.state.queryParams.selected_shopping_item_name
                }
                isCharityPremiumSubscription={this.state.queryParams.subscription === "PREMIUM"}
                source={this.state.queryParams.source}
                currency={this.state.queryParams.currency}
                redirectUrl={this.state.queryParams.redirect_url}
                socialCoreDonationId={this.state.queryParams.donation_id}
                socialCoreOpportunityUuid={
                  this.state.queryParams.opportunity_uuid
                }
                campaignId={this.state.queryParams.campaign_id}
                campaignName={this.state.queryParams.campaign_name}
                reCaptchaKey={this.state.reCaptchaKey}
                reCaptchaVersion={this.state.reCaptchaVersion}
                stripePromise={this.state.stripePromise}
              />
            </div>
        )}
        {this.state.configError && <ErrorComponent messages={['Please provide correct configuration!']} className={'text-left'} />}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.initialLoad.config,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
