/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import Config from "../../config";
import {
  isValidEmail,
  isValidName,
  isValidAlpha,
  isValidPhoneNumber,
} from "../../services/validator.service";
import Recaptcha from "../common/Recaptcha";

export class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      country: "",
      address: "",
      city: "",
      postCode: "",
      phone: "",
      joinKindlink: "",
      reCaptchaToken: null,
      domain: Config.STATIC_SITE.DOMAIN,
      shareEmail: false,
      shareText: false,
      sharePhone: false,
      sharePost: false,
      showErrors: false
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.charityDetails && nextProps.charityDetails.ecommerceProject) {
      this.changeInputValue({ shareEmail: true });
    }
  }

  changeInputValue(newValue) {
    this.setState(newValue);
  }

  setRecaptchaToken(reCaptchaToken) {
    if (this.state.reCaptchaToken) { return; }

    this.setState({ reCaptchaToken });
  }

  sendDataToParentComponent(shouldSkipPayment) {
    this.setState({ showErrors: true });
    if (this.isValidForm()) {
      this.props.sendDataToParentComponent(this.state, this.state.reCaptchaToken, shouldSkipPayment)
    }
  }

  isValidForm() {
    const { giftAid } = this.props;

    if (!isValidEmail(this.state.email)) {
      return false;
    }

    if (
      this.state.firstName === "" ||
      !isValidName(this.state.firstName) ||
      this.state.lastName === "" ||
      !isValidName(this.state.lastName)
    ) {
      return false;
    }

    if (this.state.sharePost || giftAid) {
      if (
        this.state.country === "" ||
        this.state.address === "" ||
        this.state.city === "" ||
        !isValidAlpha(this.state.city) ||
        this.state.postCode === ""
      ) {
        return false;
      }
    }

    if (this.state.sharePhone || this.state.shareText) {
      if (
        this.state.phone === "" ||
        !isValidPhoneNumber(this.state.phone) ||
        this.state.phone.length > 20
      ) {
        return false;
      }
    }

    if (this.state.joinKindlink === "") {
      return false;
    }

    if (this.state.reCaptchaToken === null) { return false; }

    return true;
  }

  render() {
    const { personalOptions, charityDetails, giftAid, errorMsg, globalError, donationDetails } = this.props;
    const isFormValid = this.isValidForm();
    const shouldSkipPayment = Number(donationDetails.amount) === 0;
    const ecommerceProject = charityDetails.ecommerceProject;

    let countries = [];
    const countryUK = "United Kingdom";
    const countryCodeUK = "GBR";
    if (typeof personalOptions !== "undefined") {
      countries = personalOptions.countries;
    }

    return (
      <div id="personal-details">
        <div
          className={
            "global-error-container " +
            (globalError || errorMsg ? "" : "hidden")
          }
        >
          <div className="error-header">
            {globalError ? "Gateway Error" : "Validation Error"}
          </div>
          <div className="error-message">{globalError || errorMsg}</div>
        </div>
        <div id="personal-details-bubble">
          <div id="personal-details-container">
            <div id="personal-details-title">Your Details</div>
            <div className="row">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="name@mailserver.com"
                onChange={(e) => this.changeInputValue({ email: e.target.value })}
              />
              <span>We need your email to send you the receipt.</span>
              <div
                className={
                  "validation-error " +
                  (this.state.email === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The email cannot be empty.
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.email !== "" &&
                    !isValidEmail(this.state.email) &&
                    this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                Invalid email format.
              </div>
            </div>
            <div id="contact-row" className="row">
              <label id="contact-label">Contact</label>
              <p>Allow {charityDetails.charity.name} to contact me via:</p>
              <div className="contact-options" id="first-contact-option">
                <input
                  className="checkbox"
                  type="checkbox"
                  disabled={ecommerceProject}
                  defaultChecked={ecommerceProject}
                  id="email-option"
                  onChange={(e) => this.changeInputValue({ shareEmail: e.target.checked })}
                />
                <label className="checkbox-label" htmlFor="email-option">
                  Email
                </label>
              </div>
              <div className="contact-options">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="mobile-option"
                  onChange={(e) => this.changeInputValue({ sharePhone: e.target.checked })}
                />
                <label className="checkbox-label" htmlFor="mobile-option">
                  Mobile
                </label>
              </div>
              <div className="contact-options">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="text-option"
                  onChange={(e) => this.changeInputValue({ shareText: e.target.checked })}
                />
                <label className="checkbox-label" htmlFor="text-option">
                  Text
                </label>
              </div>
              <div className="contact-options">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="post-option"
                  onChange={(e) => this.changeInputValue({ sharePost: e.target.checked })}
                />
                <label className="checkbox-label" htmlFor="post-option">
                  Post
                </label>
              </div>
            </div>
            <div className="row">
              <label htmlFor="first-name">Name</label>
              <input
                type="text"
                id="first-name"
                placeholder="First Name"
                onChange={(e) => this.changeInputValue({ firstName: e.target.value })}
              />
              <input
                type="text"
                id="last-name"
                placeholder="Last Name"
                onChange={(e) => this.changeInputValue({ lastName: e.target.value })}
              />
              <div
                className={
                  "validation-error " +
                  (this.state.firstName === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The first name cannot be empty.
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.firstName !== "" &&
                    !isValidName(this.state.firstName) &&
                    this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                Invalid first name format.
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.lastName === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The last name cannot be empty.
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.lastName !== "" &&
                    !isValidName(this.state.lastName) &&
                    this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                Invalid last name format.
              </div>
            </div>
            <div
              className={
                "row " + (this.state.sharePost || giftAid ? "" : "hidden")
              }
            >
              <label htmlFor="country">Country</label>
              <select
                id="country"
                className={this.state.country === "" ? "placeholder" : ""}
                defaultValue=""
                onChange={(e) => this.changeInputValue({ country: e.target.value })}
              >
                <option value="" disabled className="placeholder">
                  Country
                </option>
                <option key={countryCodeUK} value={countryUK}>
                  {countryUK}
                </option>
                {countries
                  .filter((country) => country.code !== countryCodeUK)
                  .map(function (country) {
                    return (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    );
                  })}
              </select>
              <div
                className={
                  "validation-error " +
                  (this.state.country === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The country cannot be empty.
              </div>
            </div>
            <div
              className={
                "row " + (this.state.sharePost || giftAid ? "" : "hidden")
              }
            >
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                placeholder="Address"
                onChange={(e) => this.changeInputValue({ address: e.target.value })}
              />
              <div
                className={
                  "validation-error " +
                  (this.state.address === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The address cannot be empty.
              </div>
            </div>
            <div
              className={
                "row " + (this.state.sharePost || giftAid ? "" : "hidden")
              }
            >
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                placeholder="City"
                onChange={(e) => this.changeInputValue({ city: e.target.value })}
              />
              <input
                type="text"
                id="post-code"
                placeholder="Post code"
                onChange={(e) => this.changeInputValue({ postCode: e.target.value })}
              />
              <div
                className={
                  "validation-error " +
                  (this.state.city === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The city cannot be empty.
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.city !== "" &&
                    !isValidAlpha(this.state.city) &&
                    this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The city must contain only letters
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.postCode === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The post code cannot be empty.
              </div>
            </div>
            <div
              className={
                "row " +
                (this.state.sharePhone || this.state.shareText ? "" : "hidden")
              }
            >
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                placeholder="Phone"
                onChange={(e) => this.changeInputValue({ phone: e.target.value })}
              />
              <div
                className={
                  "validation-error " +
                  (this.state.phone === "" && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The phone cannot be empty.
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.phone !== "" &&
                    !isValidPhoneNumber(this.state.phone) &&
                    this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The phone can start with + and contains only numbers and spaces
              </div>
              <div
                className={
                  "validation-error " +
                  (this.state.phone.length > 20 && this.state.showErrors
                    ? ""
                    : "hidden")
                }
              >
                The phone length is more than 20 symbols
              </div>
            </div>
          </div>
        </div>
        <div id="impact-container">
          <div id="impact-title">
            See the impact of my{" "}
            {ecommerceProject ? "contribution" : "donation"} by joining KindLink
          </div>
          <div>
            <input
              id="join-kindlink"
              className="donation-form-radio"
              type="radio"
              name="impact"
              onChange={() => this.changeInputValue({ joinKindlink: true })}
              checked={this.state.joinKindlink === true}
            />
            <label htmlFor="join-kindlink">Yes, join KindLink</label>
            <input
              id="not-join-kindlink"
              className="donation-form-radio"
              type="radio"
              name="impact"
              onChange={() => this.changeInputValue({ joinKindlink: false })}
              checked={this.state.joinKindlink === false}
            />
            <label htmlFor="not-join-kindlink">
              No, I don't want to see the impact of my
              {ecommerceProject ? " contribution" : " donation"}
            </label>
            <div
              id="impact-text"
              className={this.state.joinKindlink ? "" : "hidden"}
            >
              <p>
                We are building a platform where your charity can communicate
                back project updates and how they are doing their kind work.
                Check the box and we will save your details to inform you when
                it is up and running.
              </p>
              <p>
                By creating an account you agree with our{" "}
                <a
                  href={this.state.domain + "/terms.html"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>{" "}
                and &nbsp;
                <a
                  href={this.state.domain + "/privacy-policy.html"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
            <div
              className={
                "validation-error " +
                (this.state.joinKindlink === "" && this.state.showErrors
                  ? ""
                  : "hidden")
              }
            >
              Please choose if you would like to see the impact of your
              donation.
            </div>
          </div>
        </div>
        <div
          id="continue-button"
          disabled={!isFormValid}
          onClick={() => this.sendDataToParentComponent(shouldSkipPayment)}
        >
          Continue {!shouldSkipPayment && <span>to Payment</span>}
          <img src={require("./../../images/arrow-right.png")} alt="arrow-right" />
        </div>

        {!this.state.reCaptchaToken && this.props.currentStep === 2 && <div className="recaptcha-wrapper">
          <Recaptcha
            setRecaptchaToken={this.setRecaptchaToken.bind(this)}
            reCaptchaKey={this.props.reCaptchaKey}
            reCaptchaVersion={this.props.reCaptchaVersion}
            reCaptchaAction="pdetails" />
        </div>
        }

      </div>
    );
  }
}
