import React, { useCallback, useEffect, memo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Recaptcha = ({ setRecaptchaToken, reCaptchaKey, reCaptchaVersion }) => {

  if (reCaptchaVersion === "V2") {
    return <RecaptchaV2 setRecaptchaToken={setRecaptchaToken} reCaptchaKey={reCaptchaKey} />
  }

  if (reCaptchaVersion === "V3") {
    return <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} scriptProps={{ async: true, defer: true }} >
      <RecaptchaV3 setRecaptchaToken={setRecaptchaToken} />
    </GoogleReCaptchaProvider>
  }

  return null;
};

const RecaptchaV2 = ({ setRecaptchaToken, reCaptchaKey }) => {
  const handleCaptchaChange = useCallback((token) => {
    setRecaptchaToken(token)
  }, [setRecaptchaToken]);

  return <ReCAPTCHA sitekey={reCaptchaKey} onChange={handleCaptchaChange} badge="inline" />
}

const RecaptchaV3 = ({ setRecaptchaToken, reCaptchaAction }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) { return; }
    let token = null;

    try {
      token = await executeRecaptcha(reCaptchaAction);
    } catch (error) {
      token = null;
    }
    setRecaptchaToken(token);

  }, [executeRecaptcha, setRecaptchaToken, reCaptchaAction]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return <div></div>
}

export default memo(Recaptcha);