import React, { Component } from "react";

export class HttpRequestLoader extends Component {
  render() {
    return (
      <div id="http-request-loader" style={{ display: "none" }}>
        <div id="http-request-loader-container">
          <img src={require("./../../images/ajax-loader.gif")} alt="loader" />
          <div>Processing your request</div>
        </div>
      </div>
    );
  }
}

export default HttpRequestLoader;
