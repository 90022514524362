import React, { Component } from "react";
import { Elements } from '@stripe/react-stripe-js'
import StripePaymentForm from "./StripePaymentForm";

export class StripePaymentDetails extends Component {
  render() {
    const {
      donationFromProcessing,
      amount,
      currency,
      currencySymbol,
      errorMsg,
      globalError,
      charityDetails,
      stripePaymentResponse,
      donationDetails,
      personalDetails,
      stripePromise
    } = this.props;

    const options = { mode: 'payment', amount: Math.round(amount * 100), currency: currency };
    if (donationDetails && donationDetails.reccuringType) {
      options.setup_future_usage = 'off_session';
    }

    if (donationFromProcessing.processingId) {
      options.on_behalf_of = donationFromProcessing.processingId;
    }

    return (

      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm
          amount={amount}
          currency={currencySymbol}
          charityDetails={charityDetails}
          donationFromProcessing={donationFromProcessing}
          stripePaymentResponse={stripePaymentResponse}
          globalError={globalError}
          errorMsg={errorMsg}
          donationDetails={donationDetails}
          personalDetails={personalDetails}
        />
      </Elements>
    );
  }
}

export default StripePaymentDetails;
