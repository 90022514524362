import * as partialsActions from "../partials/actions";
import * as types from "./types";

export const charityDetails = {
  request: (charityPublicId, projectId) =>
    partialsActions.action(types.CHARITY_DETAILS[types.REQUEST], {
      charityPublicId,
      projectId,
    }),
  success: ({ charityPublicId, projectId }, response) =>
    partialsActions.action(types.CHARITY_DETAILS[types.SUCCESS], {
      charityPublicId,
      projectId,
      response,
    }),
  failure: ({ charityPublicId, projectId }, error) =>
    partialsActions.action(types.CHARITY_DETAILS[types.FAILURE], {
      charityPublicId,
      projectId,
      error,
    }),
};

export const loadCharityDetails = (
  charityPublicId,
  projectId,
  requiredFields = []
) =>
  partialsActions.action(types.LOAD_CHARITY_DETAILS, {
    charityPublicId,
    projectId,
    requiredFields,
  });

export const donation = {
  request: (donation) =>
    partialsActions.action(types.DONATION[types.REQUEST], donation),
  success: (donation, response) =>
    partialsActions.action(types.DONATION[types.SUCCESS], {
      donation,
      response,
    }),
  failure: (donation, error) =>
    partialsActions.action(types.DONATION[types.FAILURE], {
      donation,
      error,
    }),
};

export const donorEmail = {
  request: (donorEmail) =>
    partialsActions.action(types.SUPPORTER[types.REQUEST], donorEmail),
  success: (donorEmail, response) =>
    partialsActions.action(types.SUPPORTER[types.SUCCESS], {
      donorEmail,
      response,
    }),
  failure: (donorEmail, error) =>
    partialsActions.action(types.SUPPORTER[types.FAILURE], {
      donorEmail,
      error,
    }),
};
export const donationSubscription = {
  request: (params) =>
    partialsActions.action(types.SUBSCRIPTION[types.REQUEST], { params }),
  success: (requestParams, response) =>
    partialsActions.action(types.SUBSCRIPTION[types.SUCCESS], {
      requestParams,
      response,
    }),
  failure: (requestParams, error) =>
    partialsActions.action(types.SUBSCRIPTION[types.FAILURE], {
      requestParams,
      error,
    }),
};

export const createDonation = (donation, requiredFields = []) =>
  partialsActions.action(types.CREATE_DONATION, {
    donation,
    requiredFields,
  });

export const createSupporter = (donorEmail, requiredFields = []) =>
  partialsActions.action(types.CREATE_SUPPORTER, {
    donorEmail,
    requiredFields,
  });

export const cancelSubscription = (
  subscriptionId,
  referenceId,
  requiredFields = []
) =>
  partialsActions.action(types.CANCEL_SUBSCRIPTION, {
    subscriptionId,
    referenceId,
    requiredFields,
  });

export const donationToProcessing = {
  request: (donation) =>
    partialsActions.action(
      types.DONATION_TO_PROCESSING_SERVER[types.REQUEST],
      donation
    ),
  success: (donation, response) =>
    partialsActions.action(types.DONATION_TO_PROCESSING_SERVER[types.SUCCESS], {
      donation,
      response,
    }),
  failure: (donation, error) =>
    partialsActions.action(types.DONATION_TO_PROCESSING_SERVER[types.FAILURE], {
      donation,
      error,
    }),
};

export const sendDonationToDonationProcessingServer = (
  donation,
  requiredFields = []
) =>
  partialsActions.action(types.SEND_DONATION_TO_DONATION_PROCESSING_SERVER, {
    donation,
    requiredFields,
  });

export const onboardProviders = {
  request: (charityId) =>
    partialsActions.action(types.ONBOARD_PROVIDERS[types.REQUEST], charityId),
  success: (charityId, response) =>
    partialsActions.action(types.ONBOARD_PROVIDERS[types.SUCCESS], {
      charityId,
      response,
    }),
  failure: (charityId, error) =>
    partialsActions.action(types.ONBOARD_PROVIDERS[types.FAILURE], {
      charityId,
      error,
    }),
};

export const loadOnboardProviders = (charityId, requiredFields = []) =>
  partialsActions.action(types.LOAD_ONBOARD_PROVIDERS, {
    charityId,
    requiredFields,
  });

export const personalOptions = {
  request: () => partialsActions.action(types.PERSONAL_OPTIONS[types.REQUEST]),
  success: (_, response) =>
    partialsActions.action(types.PERSONAL_OPTIONS[types.SUCCESS], { response }),
  failure: (_, error) =>
    partialsActions.action(types.PERSONAL_OPTIONS[types.FAILURE], { error }),
};

export const loadPersonalOptions = () =>
  partialsActions.action(types.LOAD_PERSONAL_OPTIONS);

export const stripePaymentResponse = {
  request: (payload) =>
    partialsActions.action(types.STRIPE_PAYMENT[types.REQUEST], {
      payload,
    }),
  success: ({ payload }, response) =>
    partialsActions.action(types.STRIPE_PAYMENT[types.SUCCESS], {
      payload,
      response,
    }),
  failure: ({ payload }, error) =>
    partialsActions.action(types.STRIPE_PAYMENT[types.FAILURE], {
      payload,
      error,
    }),
};

export const submitStripePayment = (
  payload,
  referenceId,
  requiredFields = []
) =>
  partialsActions.action(types.SUBMIT_STRIPE_PAYMENT, {
    payload,
    referenceId,
    requiredFields,
  });

export * from "./types";
