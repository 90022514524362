import RequesterPrivates from "./requester.private.service";

class Requester {
  // multyton
  static instances;

  constructor(scope) {
    if (typeof Requester.instances === "undefined") {
      Requester.instances = [];
    }
    if (
      typeof scope === "undefined" ||
      typeof RequesterPrivates.scopes === "undefined" ||
      typeof RequesterPrivates.scopes[scope] === "undefined"
    ) {
      scope = "charity-core";
    }
    if (
      typeof Requester.instances[scope] !== "undefined" &&
      Requester.instances[scope]
    ) {
      return Requester.instances[scope];
    }

    this.priv = new RequesterPrivates(scope, this);
    Requester.instances[scope] = this;

    return Requester.instances[scope];
  }

  post(address, params) {
    return this.priv.post(address, params);
  }

  patch(address, params) {
    return this.priv.patch(address, params);
  }

  get(address, params) {
    return this.priv.get(address, params);
  }

  static getConfig(configName) {
    return RequesterPrivates.getConfig(configName);
  }

  getPublicToken(host) {
    return this.priv.getPublicToken(host);
  }
}

export default Requester;
