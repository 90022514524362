// use these function for cache
export const getConfigSelector = function (state) {
  if (
    typeof state.initialLoad !== "undefined" &&
    typeof state.initialLoad.config !== "undefined"
  ) {
    return state.initialLoad.config;
  } else {
    return null;
  }
};

export const getCharityCorePublicTokenSelector = function (state) {
  if (
    typeof state.initialLoad !== "undefined" &&
    typeof state.initialLoad.charityCorePublicToken !== "undefined"
  ) {
    return state.initialLoad.charityCorePublicToken;
  } else {
    return null;
  }
};

export const getDonationProcessingPublicTokenSelector = function (state) {
  if (
    typeof state.initialLoad !== "undefined" &&
    typeof state.initialLoad.donationProcessingPublicToken !== "undefined"
  ) {
    return state.initialLoad.donationProcessingPublicToken;
  } else {
    return null;
  }
};
