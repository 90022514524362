class ErrorHandler {
  constructor() {
    if (this.constructor === ErrorHandler) {
      throw new TypeError("Cannot construct ErrorHandler instances directly");
    }
  }

  serverError(error) {
    if (error.response) {
      console.log("SERVER ERROR RESPONSE");
      console.log(error.response.data);
      console.log(error.response.status);
    } else if (error.request) {
      console.log("SERVER ERROR REQUEST");
      console.log(error.request);
    } else {
      console.log("Error ", error.message);
    }
  }

  catchError(error) {
    console.log("CATCH ERROR");
    console.log(error);
  }
}

export default ErrorHandler;
