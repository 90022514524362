import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import * as actions from "../../actions";
import { isValidEmail } from "../../services/validator.service";

export class DonationStatus extends Component {
  redirectTimeout = 10000;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.actions.loadConfigFile();
    const params = queryString.parse(this.props.location.search);
    const redirectToCharityThankYouPage = params["redirectToCharityThankYouPage"];
    this.status = params["transaction.status"];
    this.errorMessage = params["transaction.errorMessage"];
    this.charityName = params["charityName"];
    this.publicId = params["publicId"];
    this.id = params["id"];
    this.setState({ showErrors: false });
    this.setState({ donorEmail: params["donorEmail"] || "" });
    this.setState({ ecommerceProject: params["ecommerceProject"] });

    if (redirectToCharityThankYouPage && this.status === "success") {
      setTimeout(() => window.top.location.href = "https://www.kindlink.org/charity-thank-you", 3000);
      return;
    }

    this.redirectUrl = localStorage.getItem("redirectUrl");

    if (this.redirectUrl !== "undefined") {
      setTimeout(() => {
        window.top.location = this.redirectUrl;
      }, this.redirectTimeout);
    }
  }

  getSubscribeForm() {
    const { subscribeResponce } = this.props;
    if (subscribeResponce) {
      if (subscribeResponce.successfull) {
        return (
          <div id="subscribe-success">
            <img
              src="/static/media/icon-success-check.e41e9cd5.svg"
              alt="icon-success"
              id="status-image"
            ></img>
            Success!
          </div>
        );
      } else {
        return (
          <div id="subscribe-success">
            Error: {subscribeResponce.errorMessage}
          </div>
        );
      }
    }

    return (
      <div>
        <p>
          Be the first to read our inclusive stories on people and causes
          changing the world for the better.
        </p>
        <div id="subscribe-form">
          <div>
            <input
              type="email"
              value={this.state.donorEmail}
              onChange={(event) =>
                this.setState({ donorEmail: event.target.value })
              }
            />
            <div
              className={
                "validation-error " +
                (this.state.donorEmail === "" && this.state.showErrors
                  ? ""
                  : "hidden")
              }
            >
              The email cannot be empty.
            </div>
            <div
              className={
                "validation-error " +
                (this.state.donorEmail !== "" &&
                !isValidEmail(this.state.donorEmail) &&
                this.state.showErrors
                  ? ""
                  : "hidden")
              }
            >
              Invalid email format.
            </div>
          </div>
          <div id="join-button" onClick={() => this.submitDonorData()}>
            Join
          </div>
        </div>
      </div>
    );
  }

  submitDonorData() {
    this.setState({ showErrors: true });
    const email = this.state.donorEmail;
    if (!email || !isValidEmail(email)) {
      return;
    }

    this.props.actions.createSupporter(this.state.donorEmail);
  }

  createUrls(Config) {
    this.staticSite = Config.STATIC_SITE.DOMAIN;
    this.charityUrl =
      Config.PUBLIC_PAGES.HOST + "/charity/" + this.publicId + "/profile";
  }

  getStatusImage() {
    let imageSrc = "";
    if (this.status && this.status === "success") {
      imageSrc = require("../../images/icon-success-check.svg");
    } else {
      imageSrc = require("../../images/icon-emoji-sad.svg");
    }

    return imageSrc;
  }

  render() {
    const { Config } = this.props;
    if (!Config) {
      return <div></div>;
    }

    const typeOfTransaction =
      this.state.ecommerceProject === "true" ? "payment" : "donation";

    this.createUrls(Config);

    if (this.status && this.status === "success") {
      return (
        <div id="donation-status">
          <img src={this.getStatusImage()} alt="status" id="status-image" />
          <h2>Processing your {typeOfTransaction}</h2>
          <div id="donation-text">
            Thank you for your {typeOfTransaction} to {this.charityName}. <br />
            You will receive {typeOfTransaction} receipt on your email, once we
            finish processing your {typeOfTransaction}.
          </div>

          {this.redirectUrl !== "undefined" ? (
            <div>You will be redirected in few seconds...</div>
          ) : null}

          <div id="status-footer">{this.getSubscribeForm()}</div>
        </div>
      );
    } else {
      return (
        <div id="donation-status">
          <img src={this.getStatusImage()} alt="status" id="status-image" />
          <h2>We are very sorry</h2>
          <div id="error-message">{this.errorMessage}</div>
          <div id="donation-text">
            Your bank account will not be charged. <br />
            In case you want to make an enquiry, please include reference number{" "}
            {this.id}
          </div>

          {this.redirectUrl !== "undefined" ? (
            <div>You will be redirected in few seconds...</div>
          ) : null}

          <p id="status-footer">
            Visit{" "}
            <a href={this.staticSite} target="_blank" rel="noopener noreferrer">
              KindLink.com
            </a>
            &nbsp; to see how we are making <br />
            the charity sector more transparent.
          </p>
        </div>
      );
    }
  }
}

DonationStatus.propTypes = {
  status: PropTypes.string,
  charityName: PropTypes.string,
  publicId: PropTypes.string,
  staticSite: PropTypes.string,
  charityUrl: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    Config: state.initialLoad ? state.initialLoad.config : null,
    subscribeResponce: state.donationFormReducer.subscribeResponce,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonationStatus);
