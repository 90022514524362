import initialLoad from "./initial/initial-load-reducer";
import donationFormReducer from "./donation-form/donation-form-reducer";
import donationSubscriptionReducer from "./donation-form/donation-subscription-reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  donationFormReducer,
  donationSubscriptionReducer,
  initialLoad,
});

export default rootReducer;
