import * as actions from "../../actions";

export default (state = {}, action) => {
  switch (action.type) {
    case actions.SUBSCRIPTION[actions.SUCCESS]:
      return Object.assign({}, state, {
        cancelSubscriptionMessage: "Successfully canceled",
        cancelSubscriptionStatus: action.response.body.subscriptionStatus,
      });
    case actions.SUBSCRIPTION[actions.FAILURE]:
      return Object.assign({}, state, {
        cancelSubscriptionMessage: action.error.response.data.message,
        cancelSubscriptionStatus: "ERROR",
      });
    default:
      return state;
  }
};
