export class DonationDetails {
  amount;
  currency;
  projectId;
  giftAidSigned;
  reccuringType;
  reccuringInterval;
  note;
  socialCoreDonationId;
  socialCoreOpportunityUuid;
  campaignId;
  campaignName;
  source;
}

export default DonationDetails;
