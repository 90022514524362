import { take, put, call, fork, select, all } from "redux-saga/effects";
import * as actions from "../actions";
import {
  loadCharityDetails,
  loadOnboardProviders,
  loadPersonalOptions,
  createDonation,
  createSupporter,
  cancelSubscription,
  sendDonationToDonationProcessingServer,
  submitStripePayment,
} from "../services/donation-form-service";
import {
  loadConfig,
  loadCharityCorePublicToken,
  loadDonationProcessingPublicToken,
} from "../services/initial-load";
import {
  getConfigSelector,
  getCharityCorePublicTokenSelector,
  getDonationProcessingPublicTokenSelector,
} from "../reducers/selectors";

// each entity defines 3 creators { request, success, failure }
const {
  charityDetails,
  onboardProviders,
  personalOptions,
  donation,
  donorEmail,
  donationSubscription,
  donationToProcessing,
  stripePaymentResponse,
  configFile,
  charityCorePublicToken,
  donationProcessingPublicToken,
  loadConfigFile,
} = actions;

/** ****************************************************************************/
/** ***************************** GENERATORS *************************************/
export const getState = (state) => state;

function* fetchEntity(entity, apiFn, id) {
  yield put(entity.request(id));
  const { response, error } = yield call(apiFn, id);
  if (response) {
    yield put(entity.success(id, response));
  } else {
    yield put(entity.failure(id, error));
  }
}

/** ****************************************************************************/
export const fetchCharityDetails = fetchEntity.bind(
  null,
  charityDetails,
  loadCharityDetails
);
export const fetchOnboardProviders = fetchEntity.bind(
  null,
  onboardProviders,
  loadOnboardProviders
);
export const fetchPersonalOptions = fetchEntity.bind(
  null,
  personalOptions,
  loadPersonalOptions
);
export const fetchCreateDonation = fetchEntity.bind(
  null,
  donation,
  createDonation
);
export const fetchCreateSupporter = fetchEntity.bind(
  null,
  donorEmail,
  createSupporter
);
export const fetchCancelSubscription = fetchEntity.bind(
  null,
  donationSubscription,
  cancelSubscription
);
export const fetchSendDonationToDonationProcessingServer = fetchEntity.bind(
  null,
  donationToProcessing,
  sendDonationToDonationProcessingServer
);
export const fetchSubmitStripePayment = fetchEntity.bind(
  null,
  stripePaymentResponse,
  submitStripePayment
);
export const fetchConfigFile = fetchEntity.bind(null, configFile, loadConfig);
export const fetchCharityCorePublicToken = fetchEntity.bind(
  null,
  charityCorePublicToken,
  loadCharityCorePublicToken
);
export const fetchDonationProcessingPublicToken = fetchEntity.bind(
  null,
  donationProcessingPublicToken,
  loadDonationProcessingPublicToken
);

// load config unless it is cached
function* getConfig() {
  const config = yield select(getConfigSelector);
  if (!config) {
    yield call(fetchConfigFile, {});
  }
}

// load public token unless it is cached
function* getCharityCorePublicToken() {
  const charityCorePublicToken = yield select(
    getCharityCorePublicTokenSelector
  );
  if (!charityCorePublicToken) {
    yield call(fetchCharityCorePublicToken, {});
  }
}

// load public token unless it is cached
function* getDonationProcessingPublicToken() {
  const donationProcessingToken = yield select(
    getDonationProcessingPublicTokenSelector
  );
  if (!donationProcessingToken) {
    yield call(fetchDonationProcessingPublicToken, {});
  }
}

/** ****************************************************************************/
/** ***************************** WATCHERS *************************************/

/** ****************************************************************************/

function* watchLoadCharityDetails() {
  while (true) {
    const { charityPublicId, projectId } = yield take(
      actions.LOAD_CHARITY_DETAILS
    );
    const state = yield select(getState);
    if (
      !state.initialLoad.charityCorePublicToken ||
      !state.initialLoad.config
    ) {
      yield put(loadConfigFile());
      yield take(actions.CHARITY_CORE_PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchCharityDetails, { charityPublicId, projectId });
  }
}

function* watchLoadOnboardProviders() {
  while (true) {
    const { charityId } = yield take(actions.LOAD_ONBOARD_PROVIDERS);
    yield call(fetchOnboardProviders, charityId);
  }
}

function* watchLoadPersonalOptions() {
  while (true) {
    yield take(actions.LOAD_PERSONAL_OPTIONS);
    const state = yield select(getState);
    if (
      !state.initialLoad.charityCorePublicToken ||
      !state.initialLoad.config
    ) {
      yield put(loadConfigFile());
      yield take(actions.CHARITY_CORE_PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchPersonalOptions, {});
  }
}

function* watchCreateDonation() {
  while (true) {
    const { donation } = yield take(actions.CREATE_DONATION);
    yield call(fetchCreateDonation, donation);
  }
}

function* watchCreateSupporter() {
  while (true) {
    const { donorEmail } = yield take(actions.CREATE_SUPPORTER);
    yield call(fetchCreateSupporter, donorEmail);
  }
}
function* watchCancelSubscription() {
  while (true) {
    const { subscriptionId, referenceId } = yield take(
      actions.CANCEL_SUBSCRIPTION
    );
    const state = yield select(getState);
    if (
      !state.initialLoad.charityCorePublicToken ||
      !state.initialLoad.config
    ) {
      yield put(loadConfigFile());
      yield take(actions.CHARITY_CORE_PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchCancelSubscription, { subscriptionId, referenceId });
  }
}

function* watchSendDonationToDonationProcessingServer() {
  while (true) {
    const { donation } = yield take(
      actions.SEND_DONATION_TO_DONATION_PROCESSING_SERVER
    );
    const state = yield select(getState);
    if (!state.initialLoad.donationProcessingPublicToken) {
      yield fork(getDonationProcessingPublicToken);
      yield take(actions.DONATION_PROCESSING_PUBLIC_TOKEN[actions.SUCCESS]);
    }
    yield call(fetchSendDonationToDonationProcessingServer, donation);
  }
}

function* watchSubmitStripePayment() {
  while (true) {
    const { payload, referenceId } = yield take(actions.SUBMIT_STRIPE_PAYMENT);
    yield call(fetchSubmitStripePayment, { payload, referenceId });
  }
}

function* watchLoadConfigFile() {
  while (true) {
    yield take(actions.LOAD_CONFIG_FILE);
    yield fork(getConfig);
    yield take(actions.CONFIG_FILE[actions.SUCCESS]);
    yield fork(getCharityCorePublicToken);
  }
}

export default function* root() {
  yield all([
    fork(watchLoadConfigFile),
    fork(watchLoadCharityDetails),
    fork(watchLoadOnboardProviders),
    fork(watchLoadPersonalOptions),
    fork(watchCreateDonation),
    fork(watchCreateSupporter),
    fork(watchCancelSubscription),
    fork(watchSendDonationToDonationProcessingServer),
    fork(watchSubmitStripePayment),
  ]);
}
