import * as partials from "../partials/types";

export const CHARITY_DETAILS = partials.createRequestTypes("CHARITY_DETAILS");
export const LOAD_CHARITY_DETAILS = "LOAD_CHARITY_DETAILS";

export const DONATION = partials.createRequestTypes("DONATION");
export const CREATE_DONATION = "CREATE_DONATION";

export const SUBSCRIPTION = partials.createRequestTypes("SUBSCRIPTION");
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

export const SUPPORTER = partials.createRequestTypes("SUPPORTER");
export const CREATE_SUPPORTER = "CREATE_SUPPORTER";

export const DONATION_TO_PROCESSING_SERVER = partials.createRequestTypes(
  "DONATION_TO_PROCESSING_SERVER"
);
export const SEND_DONATION_TO_DONATION_PROCESSING_SERVER =
  "SEND_DONATION_TO_DONATION_PROCESSING_SERVER";

export const ONBOARD_PROVIDERS =
  partials.createRequestTypes("ONBOARD_PROVIDERS");
export const LOAD_ONBOARD_PROVIDERS = "LOAD_ONBOARD_PROVIDERS";

export const STRIPE_PAYMENT = partials.createRequestTypes("STRIPE_PAYMENT");
export const SUBMIT_STRIPE_PAYMENT = "SUBMIT_STRIPE_PAYMENT";

export const PERSONAL_OPTIONS = partials.createRequestTypes("PERSONAL_OPTIONS");
export const LOAD_PERSONAL_OPTIONS = "LOAD_PERSONAL_OPTIONS";

export * from "../partials/types";
