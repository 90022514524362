import * as actions from "../../actions";

export default (state = {}, action) => {
  switch (action.type) {
    case actions.CHARITY_DETAILS[actions.FAILURE]:
      console.log("CHARITY DETAILS ERROR");
      return Object.assign({}, state, {
        errorMsg: action?.response?.message,
        globalError: action?.error?.message,
      });

    case actions.CHARITY_DETAILS[actions.SUCCESS]:
      if (typeof state.charityDetails === "undefined") {
        return Object.assign({}, state, {
          ch: action.response.body,
        });
      }
      return state;

    case actions.ONBOARD_PROVIDERS[actions.FAILURE]:
      console.log("ONBOARD PROVIDERS ERROR");
      return Object.assign({}, state, {
        errorMsg: action?.response?.message,
        globalError: action?.error?.message,
      });

    case actions.ONBOARD_PROVIDERS[actions.SUCCESS]:
      if (typeof state.onboardProviders === "undefined") {
        if (action.response.successful) {
          if (action.response.body.isOnboarded) {
            return Object.assign({}, state, {
              providers: action.response.body,
            });
          }
          return Object.assign({}, state, {
            globalError: "This charity cannot accept online donations yet!",
          });
        }
        return Object.assign({}, state, {
          errorMsg: action.response.errorMessage,
        });
      }
      return state;

    case actions.PERSONAL_OPTIONS[actions.FAILURE]:
      console.log("PERSONAL OPTIONS ERROR");
      return Object.assign({}, state, {
        errorMsg: action?.response?.message,
        globalError: action?.error?.message,
      });

    case actions.PERSONAL_OPTIONS[actions.SUCCESS]:
      if (typeof state.personalOptions === "undefined") {
        return Object.assign({}, state, {
          personalOptions: action.response.body,
        });
      }
      return state;

    case "SUPPORTER_SUCCESS":
      if (typeof state.subscribeResponce === "undefined") {
        return Object.assign({}, state, { subscribeResponce: action.response });
      }
      return state;

    case actions.DONATION[actions.FAILURE]:
      console.log("CREATE DONATION ERROR");
      return Object.assign({}, state, {
        errorMsg: action?.response?.message,
        globalError: action?.error?.message,
      });

    case actions.DONATION[actions.SUCCESS]:
      if (typeof state.donation === "undefined") {
        if (action.response.successfull) {
          return Object.assign({}, state, {
            donation: action.response.body,
            errorMsg: undefined, // Clear errors
            globalError: undefined,
          });
        }
        return Object.assign({}, state, {
          errorMsg: action.response.errorMessage,
        });
      }
      return state;

    case actions.DONATION_TO_PROCESSING_SERVER[actions.FAILURE]:
      console.log("CREATE DONATION IN PROCESSING SERVER ERROR");
      return serverError(state, action.error);

    case actions.DONATION_TO_PROCESSING_SERVER[actions.SUCCESS]:
      if (typeof state.donationFromProcessing === "undefined") {
        if (action.response.successfull) {
          return Object.assign({}, state, {
            donationFromProcessing: action.response.body,
          });
        }
        return Object.assign({}, state, {
          errorMsg: action.response.errorMessage,
        });
      }
      return state;

    case actions.STRIPE_PAYMENT[actions.FAILURE]:
      console.log("STRIPE PAYMENT ERROR");
      return Object.assign({}, state, {
        errorMsg: action?.response?.message,
        globalError: action?.error?.message,
      });

    case actions.STRIPE_PAYMENT[actions.SUCCESS]:
      if (typeof state.stripePaymentResponse === "undefined") {
        if (action.response.successfull) {
          return Object.assign({}, state, {
            stripePaymentResponse: action.response.body,
          });
        }
        return Object.assign({}, state, {
          errorMsg: action.response.errorMessage,
        });
      }
      return state;

    default:
      return state;
  }
};

function serverError(state, error) {
  if (error.response) {
    console.log("SERVER ERROR RESPONSE");
    console.log(error.response.data);
    console.log(error.response.status);
  } else if (error.request) {
    console.log("SERVER ERROR REQUEST");
    console.log(error.request);
  } else {
    console.log("Error ", error.message);
  }

  return Object.assign({}, state, {
    globalError: "There was an error. Please try again later.",
  });
}
