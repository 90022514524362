import Config from "../config";
import Requester from "./requester.service";

export const loadConfig = function () {
  return Config.init().then(
    function (response) {
      response = response.data;
      return {
        response,
      };
    },
    function (error) {
      console.log("Error loading config");
      return {
        error,
      };
    }
  );
};
export const loadCharityCorePublicToken = function () {
  const req = new Requester("charity-core");
  return req.getPublicToken(Config.CHARITY_CORE.HOST).then(
    function (response) {
      response = response.data;
      Config.setAccessToken(response.access_token);
      return {
        response,
      };
    },
    function (error) {
      return {
        error,
      };
    }
  );
};
export const loadDonationProcessingPublicToken = function () {
  const req = new Requester("donation-processing");
  return req.getPublicToken(Config.CHARITY_CORE.HOST).then(
    function (response) {
      response = response.data;
      Config.setAccessToken(
        response.access_token,
        Config.DONATION_PROCESSING.NAME
      );
      return {
        response,
      };
    },
    function (error) {
      return {
        error,
      };
    }
  );
};
