export const CurrencySymbols = {
  GBP: "£",
  USD: "$",
  EUR: "€",
  JPY: "¥",
  CHF: "Fr",
  AUD: "$",
  NZD: "$",
  CAD: "$",
  SEK: "kr",
  NOK: "kr",
};
