import React, { Component } from "react";
import Config from "../../config";

export class Footer extends Component {
  render() {
    const domain = Config.STATIC_SITE.DOMAIN;
    const { charityDetails } = this.props;

    return (
      <div id="footer">
        <img src={require("./../../images/ssl-logo.png")} alt="ssl-logo" />
        <img src={require("./../../images/pci-logo.png")} alt="pci-logo" />
        <img
          src={require("./../../images/stripe-logo.png")}
          alt="stripe-logo"
        />

        <div id="footer-text-container">
          <div className="footer-text">
            100% of your{" "}
            {charityDetails && charityDetails.ecommerceProject
              ? "payment "
              : "donation "}
            goes to your charity.
            <a
              href={domain + "/faq.html"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Bank fees apply.{" "}
            </a>
          </div>
          <div className="footer-text">
            By continuing you agree with our Terms & Conditions.
          </div>
        </div>

        <img
          src={require("./../../images/kindlink-logo.png")}
          alt="kindlink-logo"
        />

        <div id="footer-nav">
          <a
            href={domain + "/privacy-policy.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <span> | </span>
          <a
            href={domain + "/contact.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
          <span> | </span>
          <a
            href={domain + "/about.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            About
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
