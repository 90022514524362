let simultaneousLoadingsCount = 0;
const loaderId = "http-request-loader";

export const showLoader = () => {
  if (simultaneousLoadingsCount++ === 0) {
    setTimeout(function () {
      if (document.getElementById(loaderId) !== null) {
        document.getElementById(loaderId).style.display = "table";
      }
    });
  }
};

export const hideLoader = () => {
  if (--simultaneousLoadingsCount === 0) {
    if (document.getElementById(loaderId) !== null) {
      document.getElementById(loaderId).style.display = "none";
    }
  }
};
