import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./app";
import DonationStatus from "./components/donation-form/DonationStatus";
import DonationSubscription from "./components/donation-form/DonationSubscription";

const routesList = [
  { path: "/status", component: DonationStatus },
  { path: "/subscription", component: DonationSubscription },
  { path: "/:charityPublicId/:projectId", component: App },
];

const Routes = () => {
  return (
    <Router>
      <div>
        {routesList.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            component={(props) => <route.component {...props} />}
          />
        ))}
      </div>
    </Router>
  );
};

export default Routes;
