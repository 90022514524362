/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import * as actions from "../../actions";

export class DonationSubscription extends Component {
  // TODO This method is deprecated and will be removed in 17.0 - see console warnings
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.actions.loadConfigFile();
    const params = queryString.parse(this.props.location.search);
    this.subscriptionId = params["subscriptionId"];
    this.referenceId = params["referenceId"];
    this.charityName = params["charityName"];
    this.amount = params["amount"];

    this.hasRequiredFields = false;
    this.submitted = false;
    this.checkRequiredFields();
  }
  checkRequiredFields() {
    if (
      this.subscriptionId !== undefined &&
      this.subscriptionId > 0 &&
      this.referenceId !== undefined &&
      this.referenceId.length === 36
    ) {
      this.hasRequiredFields = true;
    }
  }

  submitCancelation() {
    this.submitted = true;
    this.props.actions.cancelSubscription(
      this.subscriptionId,
      this.referenceId
    );
  }

  render() {
    const { cancelSubscriptionMessage, cancelSubscriptionStatus } = this.props;
    return (
      <div id="subscription-cancelation">
        <div className={this.hasRequiredFields ? "hidden" : ""}>
          Invalid parameteres
        </div>
        <div
          id="subscription-cancelation-form"
          className={this.hasRequiredFields ? "" : "hidden"}
        >
          <p>
            Please confirm you would like to cancel this recurring donation to{" "}
            <br />
            <span className="subscription-highlight-field">
              {this.charityName}
            </span>
            <br />
            for the amount of <br />
            <span className="subscription-highlight-field">{this.amount}</span>
          </p>
          <div className={this.submitted ? "hidden" : ""}>
            <div
              id="confirm-button"
              className="button"
              onClick={() => this.submitCancelation()}
            >
              Confirm
            </div>
            <a className="changed-mind-button" href="https://www.kindlink.com">
              I've changed my mind
            </a>
          </div>
          <div
            className={
              cancelSubscriptionStatus === "CANCELED"
                ? "cancel-success"
                : "cancel-error"
            }
          >
            {cancelSubscriptionMessage}
          </div>
        </div>
      </div>
    );
  }
}

DonationSubscription.propTypes = {
  subscriptionId: PropTypes.number,
  referenceId: PropTypes.string,
  charityName: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    Config: state.initialLoad ? state.initialLoad.config : null,
    cancelSubscriptionMessage:
      state.donationSubscriptionReducer.cancelSubscriptionMessage,
    cancelSubscriptionStatus:
      state.donationSubscriptionReducer.cancelSubscriptionStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonationSubscription);
