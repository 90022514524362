// These must be the first lines in src/index.js
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import Routes from "./routes";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./store/configure-store";
import rootSaga from "./sagas";
import "./css/skeleton.css";

const store = configureStore(window.__INITIAL_STATE__);
const container = document.getElementById("root");
const root = createRoot(container);
store.runSaga(rootSaga);

root.render(
  <Provider store={store}>
    <div>
      <Routes />
    </div>
  </Provider>
);
