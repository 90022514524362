export class PersonalDetails {
  email;
  firstName;
  lastName;
  country = {};
  address;
  city;
  postCode;
  phoneNumber;
  joiningKindLink;
  anonymousDonation;
  donationDetails;
  shareEmail;
  sharePost;
  shareText;
  sharePhone;
}

export default PersonalDetails;
