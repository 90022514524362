import * as actions from "../../actions";

export default (state = [], action) => {
  switch (action.type) {
    case actions.CONFIG_FILE[actions.FAILURE]:
      console.log("CONFIG FILE ERROR");
      break;
    case actions.CONFIG_FILE[actions.SUCCESS]:
      if (typeof state.config === "undefined") {
        return Object.assign({}, state, { config: action.response });
      }
      return state;
    case actions.CHARITY_CORE_PUBLIC_TOKEN[actions.FAILURE]:
      console.log("CHARITY_CORE_PUBLIC_TOKEN FILE ERROR");
      break;
    case actions.CHARITY_CORE_PUBLIC_TOKEN[actions.SUCCESS]:
      return Object.assign({}, state, {
        charityCorePublicToken: action.response.access_token,
      });
    case actions.DONATION_PROCESSING_PUBLIC_TOKEN[actions.FAILURE]:
      console.log("DONATION_PROCESSING FILE ERROR");
      break;
    case actions.DONATION_PROCESSING_PUBLIC_TOKEN[actions.SUCCESS]:
      return Object.assign({}, state, {
        donationProcessingPublicToken: action.response.access_token,
      });
    default:
      return state;
  }
};
