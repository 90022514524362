import React from "react";
export const ErrorComponent = ({ messages, className }) => {


  return <div id="page-404">
    <div id="kindlink-logo">
      <img src={require("./../../images/kindlink-logo-b&w.png")} width="150" height="40" alt="KindLink" />
    </div>
    <div className="content-wrapper">
      <div className="body-wrapper">
        <img id="sorry-text" src={require("./../../images/sorry-frown.png")} width="254" height="210" alt="We're sorry!" />
        <div id="error-message">{
          messages?.map((m, i) => <p key={i} className={className}>{m}</p>)
        }</div>
      </div>
    </div >
  </div >;
};

