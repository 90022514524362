/* eslint-disable no-prototype-builtins */
import axios from "axios";
import ErrorHandler from "./errorHandler.service";
import CONFIG from "../config";
import { showLoader, hideLoader } from "./http-request-loader.service";

class RequesterPrivates extends ErrorHandler {
  http;
  config;
  requestScope;
  parentScope;
  static scopes;

  initProperties() {
    RequesterPrivates.scopes = {
      "charity-core": {
        baseUrl: CONFIG.CHARITY_CORE.HOST,
        headers: {
          Authorization: CONFIG.getAuthorizationToken(CONFIG.CHARITY_CORE.NAME),
          "Content-Type": "application/json; charset=utf-8",
        },
      },
      "donation-onboarding": {
        baseUrl: CONFIG.DONATION_ONBOARDING.HOST,
        headers: {
          // Donation Onboarding works with Charity Core tokens
          Authorization: CONFIG.getAuthorizationToken(CONFIG.CHARITY_CORE.NAME),
          "Content-Type": "application/json; charset=utf-8",
        },
      },
      "donation-processing": {
        baseUrl: CONFIG.DONATION_PROCESSING.HOST,
        headers: {
          Authorization: CONFIG.getAuthorizationToken(
            CONFIG.DONATION_PROCESSING.NAME
          ),
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    };
  }

  constructor(requestScope, parentScope) {
    super();
    this.initProperties();
    this.config = RequesterPrivates.scopes[requestScope];
    this.requestScope = requestScope;
    this.parentScope = parentScope;
    return this;
  }

  setConfigOptions(options) {
    this.initProperties();
    this.config = RequesterPrivates.scopes[this.requestScope];

    const config = {
      baseURL: this.config.baseUrl,
      headers: this.config.headers,
    };
    this.http = axios.create(config);

    if (typeof options !== "undefined") {
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          if (typeof options[key] === "object") {
            if (typeof config[key] === "undefined") {
              config[key] = {};
            }
            for (const innerKey in options[key]) {
              if (options[key].hasOwnProperty(innerKey)) {
                config[key][innerKey] = options[key][innerKey];
              }
            }
          } else {
            config[key] = options[key];
          }
        }
      }
    }
    if (typeof options !== "undefined" || !this.http) {
      this.http = axios.create(config);
    }
  }

  post(address, params, options) {
    showLoader();
    let that;
    try {
      that = this;
      this.setConfigOptions(options);
      return new Promise(function (resolve, reject) {
        that.http
          .post(address, params)
          .then(function (response) {
            hideLoader();
            resolve(response);
          })
          .catch(function (error, code) {
            hideLoader();
            that.serverError(error, code);
            reject(error);
          });
      });
    } catch (e) {
      hideLoader();
      that.catchError(e);
    }
  }

  get(address, params, options) {
    showLoader();
    let that;
    try {
      that = this;
      this.setConfigOptions(options);
      return new Promise(function (resolve, reject) {
        that.http
          .get(address, params)
          .then(function (response) {
            hideLoader();
            resolve(response);
          })
          .catch(function (error, code) {
            hideLoader();
            that.serverError(error, code);
            reject(error);
          });
      });
    } catch (e) {
      hideLoader();
      that.catchError(e);
    }
  }

  static getConfig(configName) {
    showLoader();
    let that;
    try {
      that = this;
      return new Promise(function (resolve, reject) {
        axios
          .get(configName)
          .then(function (response) {
            hideLoader();
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            hideLoader();
            reject(error);
          });
      });
    } catch (e) {
      hideLoader();
      that.catchError(e);
    }
  }

  getPublicToken(host) {
    let that;
    try {
      that = this;
      const options = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        baseURL: host,
      };
      this.setConfigOptions(options);
      return new Promise(function (resolve, reject) {
        that.http
          .post("/oauth/token", "grant_type=client_credentials")
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          });
      });
    } catch (e) {
      that.catchError(e);
    }
  }

  put(address, params, options) {
    showLoader();
    let that;
    try {
      that = this;
      this.setConfigOptions(options);
      this.http
        .put(address, params)
        .then(function () {
          hideLoader();
        })
        .catch(function (error, code) {
          hideLoader();
          that.serverError(error, code);
        });
    } catch (e) {
      hideLoader();
      that.catchError(e);
    }
  }

  patch(address, params, options) {
    showLoader();
    let that;
    try {
      that = this;
      this.setConfigOptions(options);
      this.http
        .patch(address, params)
        .then(function () {
          hideLoader();
        })
        .catch(function (error, code) {
          hideLoader();
          that.serverError(error, code);
        });
    } catch (e) {
      hideLoader();
      that.catchError(e);
    }
  }

  delete(address, params, options) {
    showLoader();
    let that;
    try {
      that = this;
      this.setConfigOptions(options);
      this.http
        .delete(address, params)
        .then(function () {
          hideLoader();
        })
        .catch(function (error, code) {
          hideLoader();
          that.serverError(error, code);
        });
    } catch (e) {
      hideLoader();
      that.catchError(e);
    }
  }
}

export default RequesterPrivates;
