import Requester from "./services/requester.service";
import base64 from "base-64";

class Config {
  static instance;
  CHARITY_CORE;
  DONATION_ONBOARDING;
  DONATION_PROCESSING;
  PUBLIC_PAGES;
  STATIC_SITE;
  RECAPTCHA_KEY;

  constructor() {
    this.populate(null, this);
  }

  static getInstance() {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  init() {
    const that = Config.getInstance();
    return new Promise(function (resolve, reject) {
      Requester.getConfig("/config/config.json")
        .then(function (response) {
          that.populate(response.data, that);
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  populate(options, scope) {
    if (typeof options === "undefined" || !options) {
      options = {};
      options.CHARITY_CORE = {};
      options.DONATION_ONBOARDING = {};
      options.DONATION_PROCESSING = {};
      options.PUBLIC_PAGES = {};
      options.STATIC_SITE = {};
      options.RECAPTCHA_KEY = "";
    }

    scope.CHARITY_CORE = {
      NAME: "CHARITY_CORE",
      HOST: options.CHARITY_CORE.HOST || "",
      PUBLIC_CLIENT_ID: options.CHARITY_CORE.PUBLIC_CLIENT_ID || "",
      PUBLIC_CLIENT_SECRET: options.CHARITY_CORE.PUBLIC_CLIENT_SECRET || "",
    };

    scope.DONATION_ONBOARDING = {
      NAME: "DONATION_ONBOARDING",
      HOST: options.DONATION_ONBOARDING.HOST || "",
      PREFIX: options.DONATION_ONBOARDING.PREFIX || "",
    };

    scope.DONATION_PROCESSING = {
      NAME: "DONATION_PROCESSING",
      HOST: options.DONATION_PROCESSING.HOST || "",
      PREFIX: options.DONATION_PROCESSING.PREFIX || "",
      PUBLIC_CLIENT_ID: options.DONATION_PROCESSING.PUBLIC_CLIENT_ID || "",
      PUBLIC_CLIENT_SECRET:
        options.DONATION_PROCESSING.PUBLIC_CLIENT_SECRET || "",
      STRIPE_PUBLIC_KEY: options.DONATION_PROCESSING.STRIPE_PUBLIC_KEY || "",
    };

    scope.PUBLIC_PAGES = {
      HOST: options.PUBLIC_PAGES.HOST || "",
    };

    scope.STATIC_SITE = {
      DOMAIN: options.STATIC_SITE.DOMAIN || "",
    };
    scope.RECAPTCHA_KEY = options.RECAPTCHA_KEY;
  }

  setAccessToken(token, context) {
    if (typeof context === "undefined") {
      context = "CHARITY_CORE";
    }
    this[context].ACCESS_TOKEN = token;
  }

  getAuthorizationToken(serverName) {
    switch (serverName) {
      case this.DONATION_PROCESSING.NAME:
        if (typeof this.DONATION_PROCESSING.ACCESS_TOKEN !== "undefined") {
          return "Bearer " + this.DONATION_PROCESSING.ACCESS_TOKEN;
        }
        return (
          "Basic " +
          this.getAsBase64(
            this.DONATION_PROCESSING.PUBLIC_CLIENT_ID,
            this.DONATION_PROCESSING.PUBLIC_CLIENT_SECRET
          )
        );
      default:
        if (typeof this.CHARITY_CORE.ACCESS_TOKEN !== "undefined") {
          return "Bearer " + this.CHARITY_CORE.ACCESS_TOKEN;
        }
        return (
          "Basic " +
          this.getAsBase64(
            this.CHARITY_CORE.PUBLIC_CLIENT_ID,
            this.CHARITY_CORE.PUBLIC_CLIENT_SECRET
          )
        );
    }
  }

  getAsBase64(id, secret) {
    const originalString = id + ":" + secret;
    return base64.encode(originalString);
  }
}

export default Config.getInstance();
