import Requester from "./requester.service";
import Config from "../config";

// api services
const addresses = {
  loadCharityDetails:
    "/api/public-donations/charity/{charityPublicId}/project/{projectId}",
  loadOnboardProviders:
    "/donation-onboarding/users/onboarding/charity/{charityId}",
  loadPersonalOptions: "/api/public-donations/options",
  createDonation: "/api/public-donations",
  createSupporter: "/api/public-donations/join",
  cancelSubscription: "/api/public-donations/subscriptions/{subscriptionId}",
  sendDonationToDonationProcessingServer: "/processing/donations",
  submitStripePayment: "/processing/donations/{referenceId}",
};

export const loadCharityDetails = function (charityDetails) {
  const req = new Requester("charity-core");
  const link =
    Config.CHARITY_CORE.HOST +
    addresses.loadCharityDetails
      .replace("{charityPublicId}", charityDetails.charityPublicId)
      .replace("{projectId}", charityDetails.projectId);
  return req.get(link).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Charity details request failed");
      console.error(error);

      return { error };
    }
  );
};

export const loadOnboardProviders = function (charityId) {
  const req = new Requester("donation-onboarding");
  const link =
    Config.DONATION_ONBOARDING.HOST +
    addresses.loadOnboardProviders.replace("{charityId}", charityId);
  return req.get(link).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Onboard providers request failed");
      console.error(error);

      return { error };
    }
  );
};

export const loadPersonalOptions = function () {
  const req = new Requester("charity-core");
  const link = Config.CHARITY_CORE.HOST + addresses.loadPersonalOptions;
  return req.get(link).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Personal options request failed");
      console.error(error);

      return { error };
    }
  );
};

export const createDonation = function (donation) {
  const req = new Requester("charity-core");
  const link = Config.CHARITY_CORE.HOST + addresses.createDonation;
  return req.post(link, donation).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Create donation request failed");
      console.error(error);

      return { error };
    }
  );
};

export const createSupporter = function (donorEmail) {
  const req = new Requester("charity-core");
  const link = Config.CHARITY_CORE.HOST + addresses.createSupporter;
  return req.post(link, donorEmail).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Create supporter request failed");
      console.error(error);
      return { error };
    }
  );
};

export const cancelSubscription = function (params) {
  const req = new Requester("charity-core");
  const link =
    Config.CHARITY_CORE.HOST +
    addresses.cancelSubscription.replace(
      "{subscriptionId}",
      params.subscriptionId
    ) +
    "?referenceId=" +
    params.referenceId;
  return req.post(link, {}).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Cancel subscription request failed");
      return { error };
    }
  );
};

export const sendDonationToDonationProcessingServer = function (donation) {
  const req = new Requester("donation-processing");
  const link =
    Config.DONATION_PROCESSING.HOST +
    addresses.sendDonationToDonationProcessingServer;
  return req.post(link, donation).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Send donation request failed");
      console.error(error);

      return { error };
    }
  );
};

export const submitStripePayment = function (params) {
  const req = new Requester("donation-processing");

  const link =
    Config.DONATION_PROCESSING.HOST +
    addresses.submitStripePayment.replace("{referenceId}", params.referenceId);

  return req.post(link, params.payload).then(
    function (response) {
      response = response.data;
      return { response };
    },
    function (error) {
      console.error("Submit Stripe payment request failed");
      console.error(error);

      return { error };
    }
  );
};
