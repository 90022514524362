import React, { Component } from "react";
import { isValidCardNumber, isNumeric } from "../../services/validator.service";

export class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      month: "",
      year: "",
      securityCode: "",
      formSubmitted: false,
      showErrors: false,
      showSecurityCodeInfo: false,
      creditCardImagePath: require("./../../images/card-visa.png"),
      creditCardType: "",
      creditCardSecurityCodeNumbers: 3,
    };
  }

  changeInputValue(newValue) {
    this.setState(newValue);
  }

  showSecurityCodeInfo() {
    this.setState({
      showSecurityCodeInfo: !this.state.showSecurityCodeInfo,
    });
  }

  applyCardNumberPattern(value) {
    value = value.replace(/ /g, "");
    this.findCardType(value);
    let updatedValue = "";

    for (let i = 0; i < value.length; i++) {
      if (i % 4 === 0 && i !== 0) {
        updatedValue = updatedValue + " " + value.charAt(i);
      } else {
        updatedValue = updatedValue + value.charAt(i);
      }
    }
    this.setState({ cardNumber: updatedValue });
  }

  findCardType(value) {
    const creditCardType = this.checkCreditCardType(value);
    if (creditCardType !== "") {
      this.setState({
        creditCardType: creditCardType,
        creditCardImagePath: require("./../../images/" +
          creditCardType +
          ".png"),
      });
    }
    this.setState({ creditCardType: creditCardType });
  }

  checkCreditCardType(number) {
    const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const mastercard = /^5[1-5][0-9]{14}$/;

    if (visa.test(number)) {
      this.setState({ creditCardSecurityCodeNumbers: 3 });
      return "card-visa";
    } else if (mastercard.test(number)) {
      this.setState({ creditCardSecurityCodeNumbers: 3 });
      return "card-master-card";
    } else {
      this.setState({ creditCardSecurityCodeNumbers: 3 });
      return "";
    }
  }

  submitPayment() {
    if (this.state.formSubmitted) {
      return;
    }

    this.setState({ showErrors: true, formSubmitted: true });
    if (this.isValidForm()) {
      const paymentForm = document.getElementById("donation-payment-form");

      paymentForm.submit();
    }
  }

  isValidForm() {
    if (this.state.cardNumber === "") {
      return false;
    }

    if (
      this.state.cardNumber.replace(/[^\d]/g, "").length < 13 ||
      this.state.cardNumber.replace(/[^\d]/g, "").length > 16
    ) {
      return false;
    }

    if (
      !isNumeric(this.state.cardNumber) ||
      !isValidCardNumber(this.state.cardNumber)
    ) {
      return false;
    }

    if (this.state.month === "") {
      return false;
    }

    if (this.state.year === "") {
      return false;
    }

    if (
      this.state.securityCode === "" ||
      this.state.securityCode.length !==
      this.state.creditCardSecurityCodeNumbers
    ) {
      return false;
    }

    return true;
  }

  render() {
    const { amount, currency, errorMsg, globalError, paymentUrl } =
      this.props;

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i < currentYear + 10; i++) {
      years.push(i);
    }

    return (
      <div id="payment-details">
        <div
          className={
            "global-error-container " +
            (globalError || errorMsg
              ? ""
              : "hidden")
          }
        >
          <div className="error-header">
            {globalError ? "Gateway Error" : "Validation Error"}
          </div>
          <div className="error-message">
            {globalError || errorMsg}
          </div>
        </div>
        <div id="payment-details-bubble">
          <form action={paymentUrl} method="POST" id="donation-payment-form">
            <div id="payment-details-container">
              <div id="payment-details-title">
                <div id="card-icons">
                  <img
                    src={require("./../../images/card-visa.png")}
                    alt="card-visa"
                  />
                  <img
                    src={require("./../../images/card-visa-electron.png")}
                    alt="card-visa"
                  />
                  <img
                    src={require("./../../images/card-master-card.png")}
                    alt="card-master-card"
                  />
                  <img
                    src={require("./../../images/card-maestro.png")}
                    alt="card-maestro"
                  />
                  <img
                    src={require("./../../images/card-amex.png")}
                    alt="card-amex"
                  />
                </div>
                <div id="card-details">Card Details</div>
              </div>
              <div id="card-number-row" className="row">
                <label htmlFor="card-number">Card number</label>
                <input
                  type="text"
                  id="card-number"
                  onChange={(e) => this.applyCardNumberPattern(e.target.value)}
                  value={this.state.cardNumber}
                  name="cardNum"
                />
                <img
                  src={this.state.creditCardImagePath}
                  id="credit-card-icon"
                  className={this.state.creditCardType !== "" ? "" : "hidden"}
                  alt="credit-card-icon"
                />
                <div
                  className={
                    "validation-error " +
                    (this.state.cardNumber === "" && this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  The card number cannot be empty.
                </div>
                <div
                  className={
                    "validation-error " +
                    ((!isNumeric(this.state.cardNumber) ||
                      !isValidCardNumber(this.state.cardNumber)) &&
                      this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  Invalid card number format.
                </div>
                <div
                  className={
                    "validation-error " +
                    ((this.state.cardNumber.replace(/[^\d]/g, "").length < 13 ||
                      this.state.cardNumber.replace(/[^\d]/g, "").length >
                      16) &&
                      this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  The card number must be between 13 and 16 digits.
                </div>
              </div>
              <div className="row">
                <label>Expiration Date</label>
                <select
                  id="month"
                  className={this.state.month === "" ? "placeholder" : ""}
                  defaultValue=""
                  onChange={(e) => this.changeInputValue({ month: e.target.value })}
                  name="cardExpiryMonth"
                >
                  <option value="" disabled className="placeholder">
                    MM
                  </option>
                  {months.map(function (month) {
                    return (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>
                <select
                  id="year"
                  className={this.state.year === "" ? "placeholder" : ""}
                  defaultValue=""
                  onChange={(e) => this.changeInputValue({ year: e.target.value })}
                  name="cardExpiryYear"
                >
                  <option value="" disabled className="placeholder">
                    YYYY
                  </option>
                  {years.map(function (year) {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
                <div
                  className={
                    "validation-error " +
                    (this.state.month === "" && this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  The month cannot be empty.
                </div>
                <div
                  className={
                    "validation-error " +
                    (this.state.year === "" && this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  The year cannot be empty.
                </div>
              </div>
              <div className="row" id="card-code-row">
                <label htmlFor="security-code">Security code</label>
                <input
                  id="security-code"
                  type="number"
                  onChange={(e) => this.changeInputValue({ securityCode: e.target.value })}
                  name="cvdNumber"
                />
                <div id="security-code-text">
                  The&nbsp;
                  <span>
                    {this.state.creditCardSecurityCodeNumbers}
                    &nbsp;digits
                  </span>{" "}
                  on the back of your card.{" "}
                  <span
                    id="security-code-information-question"
                    onClick={() => this.showSecurityCodeInfo()}
                  >
                    ?
                  </span>
                </div>
                <div
                  className={
                    "validation-error " +
                    (this.state.securityCode === "" && this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  The security code cannot be empty.
                </div>
                <div
                  className={
                    "validation-error " +
                    (this.state.securityCode.length !==
                      this.state.creditCardSecurityCodeNumbers &&
                      this.state.showErrors
                      ? ""
                      : "hidden")
                  }
                >
                  The security code length is not correct.
                </div>

                <div
                  className={
                    "security-code-explanation " +
                    (this.state.showSecurityCodeInfo ? "" : "hidden")
                  }
                >
                  <img
                    src={require("./../../images/card-back-security-code.png")}
                    alt="card-back-security-code"
                    className={
                      this.state.creditCardSecurityCodeNumbers !== 4
                        ? ""
                        : "hidden"
                    }
                  />
                  <img
                    src={require("./../../images/card-front-security-code-amex.png")}
                    alt="card-front-security-code-amex"
                    className={
                      this.state.creditCardSecurityCodeNumbers === 4
                        ? ""
                        : "hidden"
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          id="donate-button"
          disabled={!!this.state.formSubmitted}
          onClick={() => { this.submitPayment() }}
        >
          <img src={require("./../../images/check-white.svg")} alt="check-white" /> Donate{" "}
          {currency}
          {amount}
        </div>
      </div>
    );
  }
}
